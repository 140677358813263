// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import authOptions from '@/components/misc/login/auth.config'
import OktaSignIn from '@okta/okta-signin-widget'
import { setTokens, finishLogin, loginLog } from '@/auth'
import router from '@/router'

export default defineComponent({
  name: 'login',
  async mounted() {
    const redirect = new URLSearchParams(window.location.search).get('redirect')
    this.$nextTick(() => {
      // @ts-ignore
      this.widget = new OktaSignIn({
        logo: 'https://op1static.oktacdn.com/fs/bco/1/fs0har6v8u1ckfQbq0h7',
        baseUrl: window.location.protocol + '//',
        clientId: authOptions.clientId,
        redirectUri: authOptions.redirectUri,
        el: '#okta-signin-container',
        authParams: {
          ...authOptions,
          display: 'page'
        },
        language: 'en', 
        i18n: {
          en: {
            'primaryauth.title': 'Cincom Systems Inc.'
          }
        },
        features: {
          multiOptionalFactorEnroll: true,
          idpDiscovery: true
        },
        idpDiscovery: {
          requestContext: window.location.href.replace('/login', !redirect ? '' : redirect)
        }
      })
      // @ts-ignore
      this.widget.showSignInToGetTokens({ el: '#okta-signin-container', scopes: authOptions.scopes }).then(async tokens => {
        // this.$auth.handleLoginRedirect(tokens)
        setTokens(tokens)
        loginLog('login.ts mounted() calling finishLogin()')
        finishLogin(null)
      }).catch((err: any) => {
        throw err
      })
      // @ts-ignore
      this.widget.on('ready', this.showUnassignedUserError)
    })
    document.body.classList.add('login')
  },
  unmounted() {
    // @ts-ignore
    if (this.widget) this.widget.remove()
    document.body.classList.remove('login')
    // @ts-ignore
    this.$login_error = false
  },
  methods: {
    showUnassignedUserError() {
      // @ts-ignore
      if (!this.$login_error) return 
      const errorContainer = document.querySelector('.o-form-error-container')
      if (errorContainer && errorContainer.children.length === 0) {
        errorContainer.classList.add('o-form-has-errors')
        const errorDiv1 = document.createElement('div')
        const errorDiv2 = document.createElement('div')
        errorDiv2.classList.add('okta-form-infobox-error', 'infobox', 'infobox-error')
        errorDiv2.setAttribute('role', 'alert')
        
        const errorIcon = document.createElement('span')
        errorIcon.classList.add('icon', 'error-16')

        const errorText = document.createElement('p')
        // @ts-ignore
        errorText.innerText = this.$login_error
        
        errorDiv2.appendChild(errorIcon)
        errorDiv2.appendChild(errorText)
        errorDiv1.appendChild(errorDiv2)
        errorContainer.appendChild(errorDiv1)
      }
    }
  }
})
